:root {
  --blue-primary: rgb(15, 37, 56);
  --black: #262421;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

* {
  font-family: Calibri, "Trebuchet MS", sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: #f0f5f5;
  box-sizing: border-box;
}

#root {
  width: 100%;
  height: inherit;
  box-sizing: border-box;
  background-color: #f0f5f5;
}

button {
  cursor: pointer;
  display: block;
}

@media screen and (min-width: 1300px) {
  /*! Login */
  .all-sections-login {
    display: flex;
    width: 65em;
    height: 40em;
    margin: 3em auto;
    border: 0.15em solid #213f5b;
    border-radius: 0.6em;
    box-shadow: 0px 8px 16px 0px #213f5b;
    box-sizing: border-box;
  }

  .logo-login {
    display: flex;
    width: 48%;
    height: 100%;
    background: #f3f6f4;
    justify-content: center;
    align-items: center;
  }

  .form-login {
    width: 52%;
    height: 100%;
    background: #213f5b;
  }

  .login-header {
    text-align: center;
    color: var(--rs-gray-400);
    margin: 1.5em 0;
    font-family: Roboto;
  }

  .icon-avatar {
    display: block;
    margin: 2em auto;
    border-radius: 50%;
    background-color: #f3f6f4;
  }

  .user-input-login,
  .pwd-input-login,
  .user-input-sign,
  .pwd-input-sign {
    display: block;
    margin: 1em auto;
    width: 15em;
    height: 2em;
    font-size: 1.5em;
    color: black;
    padding-left: 2em;
    text-align: center;
    border: none;
    border-radius: 0.3em;
    outline: none;
    box-sizing: border-box;
  }

  .user-div-login,
  .pwd-div-login {
    position: relative;
  }

  .icons-input-login {
    position: absolute;
    top: 0.1em;
    left: 3em;
    font-size: 2em;
  }

  .form-login button {
    width: 18.8em;
    height: 2.5em;
    margin: 0 auto;
    color: var(--rs-gray-500);
    background-color: var(--blue-primary);
    padding: 0.2em 1em;
    border-radius: 0.5em;
    font-size: larger;
  }

  .form-login button:hover {
    color: var(--rs-gray-300);
    background-color: crimson;
    box-shadow: 2px 2px 3px rgb(2, 51, 93);
  }

  .error-p {
    text-align: center;
    margin-top: 0.5em;
    font-size: 1.2em;
    color: rgb(234, 41, 41);
  }

  /* ! Images */
  .stil-logo {
    width: 28em;
    height: auto;
    /* background-color: gray; */
  }

  .stock-logo {
    width: 18em;
    height: auto;
    margin-top: 0.2em;
    text-align: center;
  }

  .import-logo {
    width: 18em;
    height: auto;
  }

  .navbar-logo {
    width: 21em;
    height: auto;
    margin: 0.3em 0 0 1em;
  }

  /*! Hompage*/
  .container {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 1600px;
    height: 100%;
    background-color: #f0f5f5;
    box-sizing: border-box;
  }

  .menu-container {
    display: flex;
    background-image: linear-gradient(to left, rgb(4, 4, 104), rgb(94, 22, 22));
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 4em;
    z-index: 10;
    overflow: hidden;
  }

  .menu {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
  }

  .menu a {
    color: var(--rs-gray-300);
    font-size: 1.6em;
    box-sizing: border-box;
    margin: 0 0.5em;
    height: inherit;
    text-decoration: none;
  }

  .menu a:hover,
  .exit-container a:hover {
    color: rgb(31, 177, 169);
    box-sizing: border-box;
    text-decoration: none;
  }

  .menu .active {
    color: rgb(31, 177, 169);
    box-sizing: border-box;
    text-decoration: none;
  }

  .btn,
  .exit-btn {
    display: flex;
    align-items: center;
    color: rgb(31, 177, 169);
    width: max-content;
    height: 3em;
    max-width: inherit;
    font-size: 1.6em;
    background: none;
    border: none;
    box-sizing: border-box;
  }

  .exit-container {
    margin-right: 1.6em;
  }

  .btn:hover,
  .location_btn:hover,
  .exit-btn:hover {
    color: var(--rs-gray-400);
  }

  .main-section {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    border-left: 1px solid;
    overflow: hidden;
    background-color: red;
  }

  /*! Form in Alishtable*/
  .alish-main-container-flex {
    display: flex;
    border-top: 2px solid rgb(2, 0, 128);
    margin-top: 4em;
    position: relative;
  }

  .container_alTable {
    width: 25em;
    height: 44.1em;
    max-height: 50em;
    border-right: 2px solid black;
    border-left: 2px solid black;
    border-bottom: 2px solid black;
    transition: 800ms all;
    overflow: hidden;
  }

  .close-div {
    margin-left: -40em;
  }

  .top-to-bottom-btn {
    height: 100%;
    width: 2.2em;
    position: relative;
    display: flex;
    align-items: center;
  }

  .top-to-bottom-btn span {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    font-size: 1.7em;
  }

  .top-to-bottom-btn button {
    width: 2.5em;
    padding: 0;
    height: 12em;
    background-color: #353535;
    color: #d6d5d5;
    position: static;
    border-radius: 0 0.5em 0.5em 0;
    transition: 1500ms all;
    border: none;
  }

  .close button {
    background-color: transparent;
    color: black;
  }

  .alish-rigthside-table {
    margin: 0 2em;
    min-width: 600px;
    width: 101.5em;
    flex: 1;
  }

  .form {
    text-align: center;
  }

  .alish-input-div {
    position: relative;
    width: max-content;
    box-sizing: border-box;
    margin: 1em auto;
    overflow: visible;
  }

  .slide-label {
    position: absolute;
    top: -0.6em;
    left: 0.5em;
    padding: 0px 0.2em;
    margin-left: 0.5em;
    color: black;
    transition: 300ms all;
    cursor: text;
    background-color: transparent;
  }

  .marka-popup input:focus + label,
  .alish-input-div input:focus + .slide-label {
    color: #3987d0;
  }

  .icons-for-inputs {
    position: absolute;
    top: 50%;
    left: 1em;
    transform: translate(-50%, -50%);
    font-size: 1.6em;
  }

  .alishTable-form input,
  /* .alish-selects-section select, */
  .marka-popup input,
  .marka-popup select {
    display: inline-block;
    width: 15em;
    height: 1.8em;
    border-radius: 0.15em;
    text-align: left;
    padding-left: 0.7em;
    font-size: 1.5em;
    color: black;
    border: 1.7px solid #bdbbbb;
    border-top: transparent;
    border-left: transparent;
    outline: none;
    box-sizing: border-box;
  }

  .alishTable-form input:disabled,
  .alish-selects-section select:disabled {
    color: rgb(226, 12, 12);
  }

  .alishTable-form input {
    padding-left: 2.4em;
    color: #1675e0;
  }

  .usefull-links-section li {
    list-style: circle;
  }

  .alishTable-form input:focus,
  .alish-selects-section select:focus,
  .tableAlish-select-section select:focus,
  #barkod-tableSearche:focus,
  .marka-popup input:focus,
  .marka-popup select:focus {
    border: 1.5px solid #2045da;
    border-top: transparent;
    border-left: transparent;
    box-sizing: border-box;
  }

  ::placeholder {
    font-size: 0.8em;
  }

  .submit-btn {
    width: 21.5em;
    margin: 1em auto;
    padding: 0.5em 1em;
    color: var(--rs-gray-400);
    background-image: linear-gradient(45deg, rgb(154, 154, 210), blue);
    border-radius: 0.2em;
  }

  .submit-btn:disabled {
    color: var(--rs-gray-400);
    background-image: linear-gradient(
      45deg,
      rgb(154, 154, 210),
      rgb(124, 124, 195)
    );
  }

  .popup-submit-btn:hover,
  .submit-btn:hover button:hover {
    color: var(--rs-gray-200);
    background-color: rgb(2, 51, 93);
    box-shadow: 2px 2px 5px 2px rgb(2, 51, 93);
  }

  .submit-btn:disabled {
    background-color: rgb(83, 95, 107);
    box-shadow: none;
    cursor: pointer;
  }

  /* ! Marka yarat pop up */

  .create-marka {
    margin: 0.8em auto;
    display: block;
    width: 13em;
    height: 1.5em;
    border-radius: 0.2em;
    color: #19078d;
    border: 2px solid blue;
    text-align: center;
    cursor: pointer;
    font-size: 1.7em;
    transition: 300ms all;
  }

  .p {
    text-align: center;
  }

  .create-marka:hover {
    background-image: linear-gradient(-20deg, rgb(241, 13, 127), #19078d);
    color: #d9e0e6;
    border: 2px solid white;
  }

  .marka-popup {
    margin: 0 auto;
    text-align: center;
  }

  .marka-popup-div {
    display: flex;
    width: max-content;
    margin: 0.3em auto;
    text-align: center;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
  }

  .marka-popup-link-div {
    display: flex;
    margin: 0.3em auto;
    text-align: center;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
  }

  .marka-popup select,
  .marka-popup input {
    width: 10em;
    height: 1.7em;
    font-size: 1.5em;
    box-sizing: border-box;
    background-color: #f0f2f89d;
  }

  .marka-popup label {
    position: absolute;
    padding: 0 1em;
    top: 0.35em;
    left: 0;
    font-size: 1.2em;
    box-sizing: border-box;
    cursor: text;
    transition: 300ms all;
  }

  .marka-popup input:focus + label,
  .marka-popup input:valid + label {
    top: -25%;
    font-size: 1em;
  }

  .marka-popup button {
    width: 5em;
    height: 2.2em;
    line-height: 50%;
    box-sizing: border-box;
    background-color: #3498ff;
    margin-left: 0.5em;
    border-radius: 0.5em;
    color: #fff;
  }

  .marka-popup button:hover {
    background-color: #0575e6;
  }

  .marka-popup select {
    width: 7em;
    margin: 0 0.5em;
  }

  .marka-popup-ok-span {
    display: inline-block;
    margin-left: 0.6em;
    width: 2.5em;
    font-size: 1.5em;
    color: green;
  }

  .arrow {
    font-size: 2em;
    margin-top: 0.2em;
  }

  /*! Anbarlar in Alishtable*/
  .tableAlish-select-section {
    display: inline-flex;
    margin: 0.2em 0 1.2em;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .table-place-div {
    height: 36em;
    border-top: 2px solid;
    border-bottom: 2px solid;
    margin-bottom: 0.3em;
  }

  .table-alish-olculer {
    color: black;
    min-height: 410px !important;
    height: 500px !important;
  }

  .transfer-modal {
    display: flex;
    justify-content: center;
  }

  .transfer-modal input {
    outline: none;
    border-radius: 0.3em;
    font-size: 1.5em;
    margin-right: 1em;
  }

  /*! Satish page*/

  .container-satish-form {
    margin-top: 3.7em;
    width: 100vw;
    height: 100%;
  }

  .saleInput {
    width: 3em;
    text-align: center;
    border-radius: 0.3em;
    border: 1px solid #3987d0;
  }

  .saleInput:focus {
    outline: none;
    border: 1px solid black;
  }

  .kassa-table-container {
    font-size: 1.3em;
    color: black;
    width: 100vw;
    height: 30em;
    border-top: #213f5b 1px solid;
    border-bottom: #213f5b 1px solid;
    overflow-y: scroll;
    box-sizing: border-box;
  }

  .container-satish-footer {
    display: flex;
    margin: 0 3em 0 1em;
    align-items: center;
    justify-content: space-between;
  }

  .barkod-container input {
    border: #3987d0 1px solid;
    border-radius: 0.2em;
    width: 15em;
    height: 2.5em;
  }

  .barkod-container {
    position: relative;
  }

  #barkod-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    transition: 300ms all;
    cursor: text;
  }

  .barkod-container input:valid + #barkod-label,
  .barkod-container input:focus + #barkod-label {
    top: 0;
    left: 2.2em;
    background-color: white;
    padding: 0px 0.2em;
    margin: 0;
    color: #3987d0;
  }

  .barkod-container input:focus {
    outline: none;
    border: #3987d0 2px solid;
    box-sizing: border-box;
  }

  #barkod-sat button {
    background: #01075e;
    margin: 0 1em 0 0.2em;
    border-radius: 0.2em;
    color: var(--rs-gray-500);
    height: 2.47em;
  }

  #barkod-sat button:hover {
    background: #0805c7;
    color: var(--rs-gray-300);
  }

  #barkod-sat p {
    font-size: 1.5em;
    line-height: -50%;
    box-sizing: border-box;
    color: #cc0011;
  }

  .satish-submit {
    background-image: linear-gradient(20deg, rgb(183, 0, 0), rgb(50, 0, 201));
  }

  .satish-submit:disabled {
    cursor: not-allowed;
  }

  /*!Scroll of kassa-table-container*/
  .kassa-table-container::-webkit-scrollbar-track {
    width: 2em;
  }

  .kassa-table-container::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f0f5f5;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #868687;
    border-radius: 0.3em;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .kassa-table-container button {
    width: 4.2em;
    height: 1.4em;
    text-align: center;
    background-color: #f0f5f5;
    align-items: center;
    padding-bottom: 0;
    line-height: 1.3em;
    color: #213f5b;
    border-radius: 0.2em;
    border: 1px solid #213f5b;
    margin: 0 auto;
  }

  .kassa-table-container button:hover {
    background-color: #213f5b;
    color: #fff;
  }

  .tablo-test {
    margin-left: 0.5em;
    width: 82em;
    background: transparent;
  }

  .tablo-test .basket-tablo-id {
    width: 1em;
  }

  .tablo-test tr {
    border-bottom: #213f5b 1px solid;
    height: 2em;
    text-align: center;
  }

  .tablo-test thead {
    background-color: #dee4e4;
  }

  .tablo-test td,
  .tablo-test th {
    width: 10em;
    padding: 1em;
  }

  .total-satish {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.5em;
    text-align: center;
    color: black;
    height: 3.5em;
  }

  .total-satish input {
    width: 4.2em;
    border-radius: 0.2em;
    border: 1px solid;
    text-align: center;
    font-size: 1.3em;
  }

  .total-satish label {
    margin-left: 1em;
    margin-right: 0.3em;
    font-size: 1.3em;
  }

  .satish-warning {
    font-size: 3.2em;
    color: rgb(247, 6, 18);
  }

  /*! Faktura */

  .table-faktura {
    margin: 0.3em;
    text-align: center;
  }

  /* ! Admin Paneli */

  .tablo-user-list,
  .role-table {
    background-color: #f6f4f4;
    margin: 0 auto;
    width: 88em;
  }

  .user-list-div h3,
  .role-page h3,
  .wh-div h3 {
    text-align: center;
    color: #05457e;
    margin: 0;
  }

  .role-page h3 {
    color: #9f0202;
  }

  .tablo-user-list tr,
  .role-table tr {
    font-size: 1.3em;
    height: 2em;
    line-height: 50%;
  }

  .tablo-user-list tr:nth-child(2n + 1),
  .role-table tr:nth-child(2n + 1) {
    background-color: #edfaff;
    color: #615555;
  }

  .tablo-user-list tr:nth-child(2n),
  .role-table tr:nth-child(2n) {
    background-color: #dee3ec;
    color: black;
  }

  .tablo-user-list td:nth-child(1),
  .role-table td:nth-child(1) {
    width: 3em;
    text-align: center;
  }

  .tablo-user-list td:nth-child(2),
  .role-table td:nth-child(2) {
    min-width: 20em;
  }

  .tablo-user-list td:nth-child(3),
  .role-table td:nth-child(3) {
    width: 4em;
    text-align: center;
    box-sizing: border-box;
  }

  .tablo-user-list td:nth-child(4),
  .role-table td:nth-child(4) {
    width: 4em;
    text-align: center;
    box-sizing: border-box;
  }

  .tablo-user-list tr:hover,
  .role-table tr:hover {
    color: #9f0202;
    background-color: #abb1b1;
  }

  .user-modal div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 1em;
  }

  .first-div {
    height: 3.5em;
  }

  .user-modal div:nth-child(2n + 1) {
    border-bottom: 2px solid;
  }

  .user-modal div:nth-child(3) {
    margin-top: 3em;
  }

  .user-modal select {
    width: 14em;
    height: 1.8em;
    margin: 1em 1em 1em 0;
    display: inline-block;
    border-radius: 0.15em;
    text-align: left;
    padding-left: 0.7em;
    font-size: 1.6em;
    color: black;
    border: 1.7px solid #bdbbbb;
    border-top: transparent;
    outline: none;
    box-sizing: border-box;
  }

  .user-modal select:focus {
    border: 1.5px solid #2045da;
    border-top: transparent;
    box-sizing: border-box;
  }

  .user-modal span {
    color: blue;
    width: 7em;
    display: inline-block;
    margin-left: 0.7em;
  }

  .role-wh-to-user-confirm-btn:hover {
    color: white;
    background: #100f5b;
  }

  .wh-div,
  .register-container,
  .create-role-div {
    padding: 1em 0;
  }

  .create-role-div h5 {
    color: #9f0202;
  }

  .create-role-btn {
    width: 6em;
    height: 2.6em;
    box-sizing: border-box;
    background: #9f0202;
    border-radius: 0.3em;
    margin-left: 0.5em;
    color: #f6caca;
  }

  .create-role-btn:hover {
    background: #cb0000;
    color: #ffffff;
    box-shadow: 2px 2px 2px rgb(83, 7, 7);
  }

  .role-wh-delete-btn {
    background-color: transparent !important;
  }

  /*!  Register*/
  .create-role-div,
  .register-container {
    display: flex;
    justify-content: right;
    align-items: center;
    border-bottom: 1px solid;
    width: calc(100vw - 300px);
    box-sizing: border-box;
  }

  .form-register {
    display: flex;
    text-align: left;
    align-items: center;
  }

  .create-role-sub-div,
  .user-div-sign,
  .pwd-div-sign {
    margin-left: 1em;
    align-items: center;
  }

  .header-popup h5 {
    color: #05457e;
  }

  .icons-input-sign {
    font-size: 1.5em;
    color: hsl(209, 88%, 26%);
  }

  .popup-submit-btn {
    height: 2.6em;
    margin-left: 0.6em;
    border-radius: 0.3em;
    background: #08457e;
    color: rgb(239, 239, 239);
  }

  .admin-panel-side-bar {
    display: flex;
    height: 100%;
    margin-top: 3.6em;
  }

  .admin-panel-nav {
    margin-top: 3.8em;
    width: 15em;
    display: flex;
    flex-direction: column;
  }

  .admin-panel-div {
    padding: 2em;
    background-image: linear-gradient(
      to bottom,
      rgb(4, 4, 104),
      rgb(94, 22, 22)
    );
    height: 93.2vh;
    border-right: 2px solid;
  }

  .admin-side-navlink {
    font-size: 1.4em;
    margin-bottom: 1em;
    color: rgb(221, 221, 240);
  }

  .admin-panel-nav .active {
    text-decoration: none;
    color: rgb(31, 177, 169);
  }

  /* ! ICONS */
  .user-list-key-icon {
    cursor: pointer;
    font-size: 1.3em;
    color: #05457e;
  }

  .user-list-key-icon:hover {
    font-size: 1.6em;
  }

  .delete-icons {
    font-size: 1em;
    color: #606060;
  }

  .pin-icons {
    font-size: 2em;
    color: #05457e;
  }

  .pin-icons:hover,
  .delete-icons:hover {
    font-size: 1.3em;
    box-sizing: border-box;
  }

  .modal-icons {
    font-size: 1.6em;
  }

  .modal-icons:hover {
    font-size: 1.9em;
  }

  .modals-h4 {
    font-weight: normal;
    text-align: center;
    margin-bottom: 0.2em;
  }

  .delete-satish-container {
    margin: 1em auto 0;
    text-align: center;
    width: 80em;
  }

  .admin-panel-changins {
    width: 80em;
    display: flex;
    justify-content: space-between;
  }

  .sides {
    height: 30em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .admin-panel-changins div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .delete-satish-container input,
  .admin-panel-changins input,
  .admin-panel-changins select {
    width: 20em;
    height: 2em;
    margin: 0.9em 0.5em;
    font-size: 1.3em;
    border-radius: 0.3em;
  }

  .delete-satish-container input:focus,
  .admin-panel-changins input:focus,
  .admin-panel-changins select:focus {
    border: none;
    outline: 2px solid blue;
    box-sizing: border-box;
  }

  .admin-panel-changins select {
    width: 26em;
  }

  .admin-panel-changins h4 {
    text-align: center;
  }

  .sides section:nth-child(1) input,
  .sides section:nth-child(1) select,
  .sides section:nth-child(1) h4 {
    color: red;
  }

  .sides:nth-child(2) section:nth-child(1) input,
  .sides:nth-child(2) section:nth-child(1) select,
  .sides:nth-child(2) section:nth-child(1) h4 {
    color: rgb(14, 179, 14);
  }

  .sides:nth-child(1) section:nth-child(2) input,
  .sides:nth-child(1) section:nth-child(2) select,
  .sides:nth-child(1) section:nth-child(2) h4 {
    color: blue;
  }

  .sides:nth-child(2) section:nth-child(2) input,
  .sides:nth-child(2) section:nth-child(2) select,
  .sides:nth-child(2) section:nth-child(2) h4 {
    color: rgb(255, 0, 242);
  }

  .delete-satish-container button,
  .admin-panel-changins button {
    width: max-content;
    background-color: #01075e;
    color: var(--rs-gray-500);
    height: 2.2em;
    font-size: 1.1em;
    border-radius: 0.3em;
  }

  .delete-satish-container button:hover,
  .admin-panel-changins button:hover {
    background-color: #0805c7;
    box-shadow: 2px 2px 5px 3px rgb(111, 103, 103);
    color: var(--rs-gray-200);
  }

  .delete-satish-container button:disabled,
  .admin-panel-changins button:disabled {
    background-color: #b6b4b4;
    box-shadow: none;
    color: var(--rs-gray-200);
  }
}

@media screen and (max-width: 1300px) {
  /*! Login */
  .all-sections-login {
    display: flex;
    width: 65em;
    height: 40em;
    margin: 3em auto;
    border: 0.15em solid #213f5b;
    border-radius: 0.6em;
    box-shadow: 0px 8px 16px 0px #213f5b;
    box-sizing: border-box;
  }

  .logo-login {
    display: flex;
    width: 48%;
    height: 100%;
    background: #f3f6f4;
    justify-content: center;
    align-items: center;
  }

  .form-login {
    width: 52%;
    height: 100%;
    background: #213f5b;
  }

  .login-header {
    text-align: center;
    color: var(--rs-gray-400);
    margin: 1.5em 0;
    font-family: Roboto;
  }

  .icon-avatar {
    display: block;
    margin: 2em auto;
    border-radius: 50%;
    background-color: #f3f6f4;
  }

  .user-input-login,
  .pwd-input-login,
  .user-input-sign,
  .pwd-input-sign {
    display: block;
    margin: 1em auto;
    width: 15em;
    height: 2em;
    font-size: 1.5em;
    color: black;
    padding-left: 2em;
    text-align: center;
    border: none;
    border-radius: 0.3em;
    outline: none;
    box-sizing: border-box;
  }

  .user-div-login,
  .pwd-div-login {
    position: relative;
  }

  .icons-input-login {
    position: absolute;
    top: 0.1em;
    left: 3em;
    font-size: 2em;
  }

  .form-login button {
    width: 18.8em;
    height: 2.5em;
    margin: 0 auto;
    color: var(--rs-gray-500);
    background-color: var(--blue-primary);
    padding: 0.2em 1em;
    border-radius: 0.5em;
    font-size: larger;
  }

  .form-login button:hover {
    color: var(--rs-gray-300);
    background-color: crimson;
    box-shadow: 2px 2px 3px rgb(2, 51, 93);
  }

  .error-p {
    text-align: center;
    margin-top: 0.5em;
    font-size: 1.2em;
    color: rgb(234, 41, 41);
  }

  /* ! Images */
  .stil-logo {
    width: 28em;
    height: auto;
  }

  .stock-logo {
    width: 15em;
    height: auto;
    margin-top: 0.2em;
    text-align: center;
  }

  .import-logo {
    width: 15em;
    height: auto;
  }

  .navbar-logo {
    width: 18em;
    height: auto;
    margin: 0.3em 0 0 0.5em;
  }

  /*! Hompage*/
  .container {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 1300px;
    height: 100%;
    background-color: #f0f5f5;
    box-sizing: border-box;
  }

  .menu-container {
    display: flex;
    background-image: linear-gradient(to left, rgb(4, 4, 104), rgb(94, 22, 22));
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 3.2em;
    z-index: 10;
    overflow: hidden;
  }

  .menu {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
  }

  .menu a {
    color: var(--rs-gray-300);
    font-size: 1.5em;
    box-sizing: border-box;
    margin: 0 0.3em;
    height: inherit;
    text-decoration: none;
  }

  .menu a:hover,
  .exit-container a:hover {
    color: rgb(31, 177, 169);
    box-sizing: border-box;
    text-decoration: none;
  }

  .menu .active {
    color: rgb(31, 177, 169);
    box-sizing: border-box;
    text-decoration: none;
  }

  .btn,
  .exit-btn {
    display: flex;
    align-items: center;
    color: rgb(31, 177, 169);
    width: max-content;
    height: 3em;
    max-width: inherit;
    font-size: 1.5em;
    background: none;
    border: none;
    box-sizing: border-box;
  }

  .exit-container {
    margin-right: 1.6em;
  }

  .btn:hover,
  .location_btn:hover,
  .exit-btn:hover {
    color: var(--rs-gray-400);
  }

  .main-section {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    border-left: 1px solid;
    overflow: hidden;
  }

  /*! Form in Alishtable*/
  .alish-main-container-flex {
    display: flex;
    border-top: 1.5px solid rgb(2, 0, 128);
    margin-top: 3em;
    position: relative;
  }

  .container_alTable {
    width: 25em;
    height: 44.1em;
    max-height: 50em;
    border-right: 1.8px solid black;
    border-left: 1.8px solid black;
    border-bottom: 1.8px solid black;
    transition: 800ms all;
    overflow: hidden;
  }

  .close-div {
    margin-left: -40em;
  }

  .top-to-bottom-btn {
    height: 100%;
    width: 1.7em;
    position: relative;
    display: flex;
    align-items: center;
  }

  .top-to-bottom-btn span {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    font-size: 1.7em;
  }

  .top-to-bottom-btn button {
    width: 2.1em;
    padding: 0;
    height: 12em;
    background-color: #353535;
    color: #d6d5d5;
    position: static;
    border-radius: 0 0.5em 0.5em 0;
    transition: 1500ms all;
    border: none;
  }

  .close button {
    background-color: transparent;
    color: black;
  }

  .alish-rigthside-table {
    margin: 0 1em;
    min-width: 600px;
    width: 83.5em;
    flex: 1;
  }

  .form {
    text-align: center;
  }

  .alish-input-div {
    position: relative;
    width: max-content;
    box-sizing: border-box;
    margin: 1em auto;
    overflow: visible;
  }

  .slide-label {
    position: absolute;
    top: -0.6em;
    left: 0.5em;
    padding: 0px 0.2em;
    margin-left: 0.5em;
    color: black;
    transition: 300ms all;
    cursor: text;
    background-color: transparent;
  }

  .marka-popup input:focus + label,
  .alish-input-div input:focus + .slide-label {
    color: #3987d0;
  }

  .icons-for-inputs {
    position: absolute;
    top: 50%;
    left: 1em;
    transform: translate(-50%, -50%);
    font-size: 1.6em;
  }

  .alishTable-form input,
  .alish-selects-section select,
  .marka-popup input,
  .marka-popup select {
    display: inline-block;
    width: 15em;
    height: 1.8em;
    border-radius: 0.15em;
    text-align: left;
    padding-left: 0.7em;
    font-size: 1.5em;
    color: black;
    border: 1.7px solid #bdbbbb;
    border-top: transparent;
    border-left: transparent;
    outline: none;
    box-sizing: border-box;
  }

  .alishTable-form input:disabled,
  .alish-selects-section select:disabled {
    color: gray;
  }

  .alishTable-form input {
    padding-left: 2.4em;
  }

  .usefull-links-section li {
    list-style: circle;
  }

  .alishTable-form input:focus,
  .alish-selects-section select:focus,
  .tableAlish-select-section select:focus,
  #barkod-tableSearche:focus,
  .marka-popup input:focus,
  .marka-popup select:focus {
    border: 1.5px solid #2045da;
    border-top: transparent;
    border-left: transparent;
    box-sizing: border-box;
  }

  ::placeholder {
    font-size: 0.8em;
  }

  .submit-btn {
    width: 21.5em;
    margin: 1em auto;
    padding: 0.5em 1em;
    color: var(--rs-gray-400);
    background-image: linear-gradient(45deg, rgb(154, 154, 210), blue);
    border-radius: 0.2em;
  }

  .submit-btn:disabled {
    color: var(--rs-gray-400);
    background-image: linear-gradient(
      45deg,
      rgb(154, 154, 210),
      rgb(124, 124, 195)
    );
  }

  .popup-submit-btn:hover,
  .submit-btn:hover,
  .tableAlish-select-section button:hover {
    color: var(--rs-gray-200);
    background-color: rgb(2, 51, 93);
    box-shadow: 2px 2px 5px 2px rgb(2, 51, 93);
  }

  .submit-btn:disabled {
    background-color: rgb(83, 95, 107);
    box-shadow: none;
    cursor: pointer;
  }

  /* ! Marka yarat pop up */

  .create-marka {
    margin: 0.8em auto;
    display: block;
    width: 13em;
    height: 1.5em;
    border-radius: 0.2em;
    color: #19078d;
    border: 2px solid blue;
    text-align: center;
    cursor: pointer;
    font-size: 1.7em;
    transition: 300ms all;
  }

  .p {
    text-align: center;
  }

  .create-marka:hover {
    background-image: linear-gradient(-20deg, rgb(241, 13, 127), #19078d);
    color: #d9e0e6;
    border: 2px solid white;
  }

  .marka-popup {
    margin: 0 auto;
    text-align: center;
  }

  .marka-popup div {
    display: flex;
    width: max-content;
    margin: 0.3em auto;
    text-align: center;
    align-items: center;
    position: relative;
    box-sizing: border-box;
  }

  .marka-popup select,
  .marka-popup input {
    width: 10em;
    height: 1.7em;
    font-size: 1.5em;
    box-sizing: border-box;
    background-color: #f0f2f89d;
  }

  .marka-popup label {
    position: absolute;
    padding: 0 1em;
    top: 0.35em;
    left: 0;
    font-size: 1.2em;
    box-sizing: border-box;
    cursor: text;
    transition: 300ms all;
  }

  .marka-popup input:focus + label,
  .marka-popup input:valid + label {
    top: -25%;
    font-size: 1em;
  }

  .marka-popup button {
    width: 5em;
    height: 2.2em;
    line-height: 50%;
    box-sizing: border-box;
    background-color: #3498ff;
    margin-left: 0.5em;
    border-radius: 0.5em;
    color: #fff;
  }

  .marka-popup button:hover {
    background-color: #0575e6;
  }

  .marka-popup select {
    width: 7em;
    margin: 0 0.5em;
  }

  .marka-popup-ok-span {
    display: inline-block;
    margin-left: 0.6em;
    width: 2.5em;
    font-size: 1.5em;
    color: green;
  }

  .arrow {
    font-size: 2em;
    margin-top: 0.2em;
  }

  /*! Anbarlar in Alishtable*/
  .tableAlish-select-section {
    display: inline-flex;
    margin: 0.2em 0 1.2em;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .table-place-div {
    height: 36em;
    border-top: 2px solid;
    border-bottom: 2px solid;
    margin-bottom: 0.3em;
  }

  .table-alish-olculer {
    color: black;
    width: 1170px !important;
    min-height: 410px !important;
    height: 500px !important;
  }

  .transfer-modal {
    display: flex;
    justify-content: center;
  }

  .transfer-modal input {
    outline: none;
    border-radius: 0.3em;
    font-size: 1.5em;
    margin-right: 1em;
  }

  /*! Satish page*/

  .container-satish-form {
    margin-top: 3.7em;
    width: 100vw;
    height: 100%;
  }

  .saleInput {
    width: 3em;
    text-align: center;
    border-radius: 0.3em;
    border: 1px solid #3987d0;
  }

  .saleInput:focus {
    outline: none;
    border: 1px solid black;
  }

  .kassa-table-container {
    font-size: 1.3em;
    color: black;
    width: 100vw;
    height: 30em;
    border-top: #213f5b 1px solid;
    border-bottom: #213f5b 1px solid;
    overflow-y: scroll;
    box-sizing: border-box;
  }

  .container-satish-footer {
    display: flex;
    margin: 0 3em 0 1em;
    align-items: center;
    justify-content: space-between;
  }

  .barkod-container input {
    border: #3987d0 1px solid;
    border-radius: 0.2em;
    width: 15em;
    height: 2.5em;
  }

  .barkod-container {
    position: relative;
  }

  #barkod-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    transition: 300ms all;
    cursor: text;
  }

  .barkod-container input:valid + #barkod-label,
  .barkod-container input:focus + #barkod-label {
    top: 0;
    left: 2.2em;
    background-color: white;
    padding: 0px 0.2em;
    margin: 0;
    color: #3987d0;
  }

  .barkod-container input:focus {
    outline: none;
    border: #3987d0 2px solid;
    box-sizing: border-box;
  }

  #barkod-sat button {
    background: #01075e;
    margin: 0 1em 0 0.2em;
    border-radius: 0.2em;
    color: var(--rs-gray-500);
    height: 2.47em;
  }

  #barkod-sat button:hover {
    background: #0805c7;
    color: var(--rs-gray-300);
  }

  #barkod-sat p {
    font-size: 1.5em;
    line-height: -50%;
    box-sizing: border-box;
    color: #cc0011;
  }

  .satish-submit {
    background-image: linear-gradient(20deg, rgb(183, 0, 0), rgb(50, 0, 201));
  }

  /*!Scroll of kassa-table-container*/
  .kassa-table-container::-webkit-scrollbar-track {
    width: 2em;
  }

  .kassa-table-container::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f0f5f5;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #868687;
    border-radius: 0.3em;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .kassa-table-container button {
    width: 4.2em;
    height: 1.4em;
    text-align: center;
    background-color: #f0f5f5;
    align-items: center;
    padding-bottom: 0;
    line-height: 1.3em;
    color: #213f5b;
    border-radius: 0.2em;
    border: 1px solid #213f5b;
    margin: 0 auto;
  }

  .kassa-table-container button:hover {
    background-color: #213f5b;
    color: #fff;
  }

  .tablo-test {
    margin-left: 0.5em;
    width: 82em;
    background: transparent;
  }

  .tablo-test .basket-tablo-id {
    width: 1em;
  }

  .tablo-test tr {
    border-bottom: #213f5b 1px solid;
    height: 2em;
    text-align: center;
  }

  .tablo-test thead {
    background-color: #dee4e4;
  }

  .tablo-test td,
  .tablo-test th {
    width: 10em;
    padding: 1em;
  }

  .total-satish {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.5em;
    text-align: center;
    color: black;
    height: 3.5em;
  }

  .total-satish input {
    width: 4.2em;
    border-radius: 0.2em;
    border: 1px solid;
    text-align: center;
    font-size: 1.3em;
  }

  .total-satish label {
    margin-left: 1em;
    margin-right: 0.3em;
    font-size: 1.3em;
  }

  .satish-warning {
    font-size: 3.2em;
    color: rgb(247, 6, 18);
  }

  /*! Faktura */

  .table-faktura {
    margin: 0.3em;
    text-align: center;
  }

  /* ! Admin Paneli */

  .tablo-user-list,
  .role-table {
    background-color: #f6f4f4;
    margin: 0 auto;
    width: 88em;
  }

  .user-list-div h3,
  .role-page h3,
  .wh-div h3 {
    text-align: center;
    color: #05457e;
    margin: 0;
  }

  .role-page h3 {
    color: #9f0202;
  }

  .tablo-user-list tr,
  .role-table tr {
    font-size: 1.3em;
    height: 2em;
    line-height: 50%;
  }

  .tablo-user-list tr:nth-child(2n + 1),
  .role-table tr:nth-child(2n + 1) {
    background-color: #edfaff;
    color: #615555;
  }

  .tablo-user-list tr:nth-child(2n),
  .role-table tr:nth-child(2n) {
    background-color: #dee3ec;
    color: black;
  }

  .tablo-user-list td:nth-child(1),
  .role-table td:nth-child(1) {
    width: 3em;
    text-align: center;
  }

  .tablo-user-list td:nth-child(2),
  .role-table td:nth-child(2) {
    min-width: 20em;
  }

  .tablo-user-list td:nth-child(3),
  .role-table td:nth-child(3) {
    width: 4em;
    text-align: center;
    box-sizing: border-box;
  }

  .tablo-user-list td:nth-child(4),
  .role-table td:nth-child(4) {
    width: 4em;
    text-align: center;
    box-sizing: border-box;
  }

  .tablo-user-list tr:hover,
  .role-table tr:hover {
    color: #9f0202;
    background-color: #abb1b1;
  }

  .user-modal div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 1em;
  }

  .first-div {
    height: 3.5em;
  }

  .user-modal div:nth-child(2n + 1) {
    border-bottom: 2px solid;
  }

  .user-modal div:nth-child(3) {
    margin-top: 3em;
  }

  .user-modal select {
    width: 14em;
    height: 1.8em;
    margin: 1em 1em 1em 0;
    display: inline-block;
    border-radius: 0.15em;
    text-align: left;
    padding-left: 0.7em;
    font-size: 1.6em;
    color: black;
    border: 1.7px solid #bdbbbb;
    border-top: transparent;
    outline: none;
    box-sizing: border-box;
  }

  .user-modal select:focus {
    border: 1.5px solid #2045da;
    border-top: transparent;
    box-sizing: border-box;
  }

  .user-modal span {
    color: blue;
    width: 7em;
    display: inline-block;
    margin-left: 0.7em;
  }

  .role-wh-to-user-confirm-btn:hover {
    color: white;
    background: #100f5b;
  }

  .wh-div,
  .register-container,
  .create-role-div {
    padding: 1em 0;
  }

  .create-role-div h5 {
    color: #9f0202;
  }

  .create-role-btn {
    width: 6em;
    height: 2.6em;
    box-sizing: border-box;
    background: #9f0202;
    border-radius: 0.3em;
    margin-left: 0.5em;
    color: #f6caca;
  }

  .create-role-btn:hover {
    background: #cb0000;
    color: #ffffff;
    box-shadow: 2px 2px 2px rgb(83, 7, 7);
  }

  .role-wh-delete-btn {
    background-color: transparent !important;
  }

  /*!  Register*/
  .create-role-div,
  .register-container {
    display: flex;
    justify-content: right;
    align-items: center;
    border-bottom: 1px solid;
    width: calc(100vw - 300px);
    box-sizing: border-box;
  }

  .form-register {
    display: flex;
    text-align: left;
    align-items: center;
  }

  .create-role-sub-div,
  .user-div-sign,
  .pwd-div-sign {
    margin-left: 1em;
    align-items: center;
  }

  .header-popup h5 {
    color: #05457e;
  }

  .icons-input-sign {
    font-size: 1.5em;
    color: hsl(209, 88%, 26%);
  }

  .popup-submit-btn {
    height: 2.6em;
    margin-left: 0.6em;
    border-radius: 0.3em;
    background: #08457e;
    color: rgb(239, 239, 239);
  }

  .admin-panel-side-bar {
    display: flex;
    height: 100%;
    margin-top: 3.6em;
  }

  .admin-panel-nav {
    margin-top: 3.8em;
    width: 15em;
    display: flex;
    flex-direction: column;
  }

  .admin-panel-div {
    padding: 2em;
    background-image: linear-gradient(
      to bottom,
      rgb(4, 4, 104),
      rgb(94, 22, 22)
    );
    height: 93.2vh;
    border-right: 2px solid;
  }

  .admin-side-navlink {
    font-size: 1.4em;
    margin-bottom: 1em;
    color: rgb(221, 221, 240);
  }

  .admin-panel-nav .active {
    text-decoration: none;
    color: rgb(31, 177, 169);
  }

  /* ! ICONS */
  .user-list-key-icon {
    cursor: pointer;
    font-size: 1.3em;
    color: #05457e;
  }

  .user-list-key-icon:hover {
    font-size: 1.6em;
  }

  .delete-icons {
    font-size: 1em;
    color: #606060;
  }

  .pin-icons {
    font-size: 2em;
    color: #05457e;
  }

  .pin-icons:hover,
  .delete-icons:hover {
    font-size: 1.3em;
    box-sizing: border-box;
  }

  .modal-icons {
    font-size: 1.6em;
  }

  .modal-icons:hover {
    font-size: 1.9em;
  }

  .modals-h4 {
    font-weight: normal;
    text-align: center;
    margin-bottom: 0.2em;
  }

  .delete-satish-container {
    margin: 1em auto 0;
    text-align: center;
    width: 80em;
  }

  .admin-panel-changins {
    width: 80em;
    display: flex;
    justify-content: space-between;
  }

  .sides {
    height: 30em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .admin-panel-changins div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .delete-satish-container input,
  .admin-panel-changins input,
  .admin-panel-changins select {
    width: 20em;
    height: 2em;
    margin: 0.9em 0.5em;
    font-size: 1.3em;
    border-radius: 0.3em;
  }

  .delete-satish-container input:focus,
  .admin-panel-changins input:focus,
  .admin-panel-changins select:focus {
    border: none;
    outline: 2px solid blue;
    box-sizing: border-box;
  }

  .admin-panel-changins select {
    width: 26em;
  }

  .admin-panel-changins h4 {
    text-align: center;
  }

  .sides section:nth-child(1) input,
  .sides section:nth-child(1) select,
  .sides section:nth-child(1) h4 {
    color: red;
  }

  .sides:nth-child(2) section:nth-child(1) input,
  .sides:nth-child(2) section:nth-child(1) select,
  .sides:nth-child(2) section:nth-child(1) h4 {
    color: rgb(14, 179, 14);
  }

  .sides:nth-child(1) section:nth-child(2) input,
  .sides:nth-child(1) section:nth-child(2) select,
  .sides:nth-child(1) section:nth-child(2) h4 {
    color: blue;
  }

  .sides:nth-child(2) section:nth-child(2) input,
  .sides:nth-child(2) section:nth-child(2) select,
  .sides:nth-child(2) section:nth-child(2) h4 {
    color: rgb(255, 0, 242);
  }

  .delete-satish-container button,
  .admin-panel-changins button {
    width: max-content;
    background-color: #01075e;
    color: var(--rs-gray-500);
    height: 2.2em;
    font-size: 1.1em;
    border-radius: 0.3em;
  }

  .delete-satish-container button:hover,
  .admin-panel-changins button:hover {
    background-color: #0805c7;
    box-shadow: 2px 2px 5px 3px rgb(111, 103, 103);
    color: var(--rs-gray-200);
  }

  .delete-satish-container button:disabled,
  .admin-panel-changins button:disabled {
    background-color: #b6b4b4;
    box-shadow: none;
    color: var(--rs-gray-200);
  }
}
